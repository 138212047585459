<template>
  <v-container fluid>
    <Header headerClass="valutare-aria" title="Valutare l'aria" />
    <v-container page id="contenuto">
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Rete di monitoraggio</h2>
          <div class="w-80">
            <p class="mt-40">
              Il Sistema Regionale di Rilevamento della Qualità dell’Aria
              (SRRQA) rappresenta il
              <storng
                >sistema di stazioni di misurazione degli inquinanti
                atmosferici</storng
              >
              da utilizzare, insieme ad altri strumenti qui citati, per valutare
              la qualità dell’aria.
            </p>
          </div>
          <v-card flat>
            <v-list-item three-line class="center">
              <v-list-item-content>
                <p>
                  La rete piemontese è attualmente composta da 58 stazioni
                  fisse. Di queste, alcune sono di proprietà privata e situate
                  in prossimità di impianti industriali di cui è necessario
                  valutare l’impatto: un inceneritore, una centrale
                  termoelettrica a metano e una azienda chimica. Presso le
                  stazioni sono installati sia
                  <strong>analizzatori automatici</strong> - che forniscono dati
                  in continuo ad intervalli regolari di tutti i parametri
                  monitorati, con cadenza generalmente oraria - sia
                  <strong>campionatori</strong>.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <div class="w-80">
            <p class="pt-40">
              Solo per il particolato PM<sub>10</sub> e PM<sub>2,5</sub> sono
              utilizzati sia analizzatori automatici sia campionatori per la
              gravimetria: i primi producono dati orari o giornalieri, mentre i
              secondi solo giornalieri. I campionatori raccolgono il PM su dei
              filtri che sono poi analizzati in laboratorio. I dati di IPA
              (Idrocarburi Policiclici Aromatici) e Metalli pesanti, sono
              ottenuti in laboratorio tramite l’analisi del particolato
              depositato e sono forniti con cadenza mensile utilizzando però i
              filtri di PM<sub>10</sub>
              campionati quotidianamente.
            </p>
          </div>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuValutareAria page="rete-monitoraggio" />
        </v-col>
      </v-row>
    </v-container>
    <div class="rete-monitoraggio">
      <v-container>
        <v-row justify-center>
          <v-col cols="8" offset="2">
            <p>
              I punti di misura sono dislocati sul territorio regionale in
              funzione della zonizzazione del territorio effettuata ai sensi
              dell’art. 3 del Decreto 155/2010, che prevede le seguenti zone di
              qualità dell’aria:
            </p>
            <ul class="no-list">
              <li>zona di pianura IT0119</li>
              <li>zona di collina IT0120</li>
              <li>zona di montagna IT0121</li>
              <li>
                zona regionale - escluso l’agglomerato di Torino - per l’ozono
                IT0122.
              </li>
            </ul>
            <p class="pt-40">
              <a
                target="_blank"
                href="http://www.regione.piemonte.it/governo/bollettino/abbonati/2020/03/attach/dgr_00903_1050_30122019.pdf"
                class="v-btn--active v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--router theme--light v-size--default"
                title="Questo link si apre in una nuova scheda"
              >
                <span class="v-btn__content">
                  TESTO DELLA DELIBERA REGIONALE
                  <em class="v-icon notranslate mdi mdi-launch"></em>
                </span>
              </a>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container hexagon>
      <v-row>
        <v-col class="col-md-9 col-12">
          <div class="w-80">
            <p class="mt-40">
              Più in dettaglio, le <strong>stazioni di traffico</strong> sono
              collocate in modo da misurare prevalentemente gli inquinanti
              provenienti da emissioni veicolari.<br />
              Le <strong>stazioni di fondo</strong> rilevano livelli di
              inquinamento non direttamente influenzati da singole sorgenti ma
              riferibili al loro contributo integrato, mentre quelle
              <strong>industriali</strong>
              rilevano l’eventuale contributo delle limitrofe attività
              produttive.<br />
              I punti di misura possono essere descritti in relazione alla loro
              collocazione per
              <strong>tipo di zona</strong> (urbana/suburbana/rurale) o per
              <strong>tipo di stazione</strong>
              (traffico/fondo/industriale).
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuValutareAria from "@/views/valutare-aria/MenuValutareAria";

export default {
  components: {
    Header,
    MenuValutareAria
  }
};
</script>
